import { WobbleCard } from "../ui/wobble-card";

export default function HomeFeatureShowcase() {
  return (
    <section className="py-4">
      <div className="container space-y-32 pt-14 px-4 md:px-6">
        <div className="flex flex-col items-center justify-center space-y-4 text-center gap-8">
          <h2 className="text-4xl font-bold text-neutral-800 max-w-xxl mx-auto tracking-tighter sm:text-6xl">
            Everything you need for organic <br />
            <span className="text-primary">growth</span>
          </h2>
          <p className="max-w-xl text-center text-lg text-neutral-600">
            We empower both marketers and developers through a comprehensive
            suite of tools and integrations.
          </p>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 w-full">
          <WobbleCard
            containerClassName="col-span-1 lg:col-span-2 h-full bg-primary min-h-[500px] lg:min-h-[300px]"
            className=""
          >
            <div className="max-w-xs">
              <h2 className="text-3xl font-inter font-bold text-white">
                Referral APIs
              </h2>
              <p className="mt-4 text-left text-base/6 text-white">
                Our API solutions allow developers to easily integrate Reftree
                with any technical stack. Receive real-time updates powered by
                webhooks.
              </p>
            </div>
            <img
              src="/illustrations/undraw_world_re_768g.svg"
              width={400}
              height={400}
              alt="linear demo image"
              className="absolute -right-4 lg:-right-16 xl:right-10 -bottom-10 object-contain rounded-2xl"
            />
          </WobbleCard>
          <WobbleCard containerClassName="col-span-1 min-h-[300px] bg-green-600">
            <h2 className="max-w-80 text-left text-balance text-base md:text-xl lg:text-3xl font-semibold tracking-[-0.015em] text-white">
              Short referral links on your domain
            </h2>
            <p className="mt-4 max-w-[26rem] text-left text-base/6 text-white">
              Take advantage of your shiny domains and get comprehensive click
              analytics. Set it up in minutes, not days.
            </p>
          </WobbleCard>
          <WobbleCard containerClassName="col-span-1 lg:col-span-3 bg-green-500 min-h-[500px] lg:min-h-[600px] xl:min-h-[300px]">
            <div className="max-w-sm">
              <h2 className="text-3xl font-inter font-bold text-white">
                Management dashboard
              </h2>
              <p className="mt-4 max-w-[26rem] text-left  text-base/6 text-white">
                Create campaigns, define milestones for your users to reach and
                the rewards they receive. Track their progress in realtime and
                do adjustments on the fly.
              </p>
            </div>
            <img
              src="/illustrations/undraw_control_panel_re_y3ar.svg"
              width={400}
              height={400}
              alt="linear demo image"
              className="absolute -right-4 lg:right-48 -bottom-10 object-contain rounded-2xl"
            />
          </WobbleCard>
          <WobbleCard containerClassName="col-span-1 min-h-[300px] bg-green-600">
            <h2 className="max-w-80 text-left text-balance text-base md:text-xl lg:text-3xl font-semibold tracking-[-0.015em] text-white">
              Strategies that work for your product
            </h2>
            <p className="mt-4 max-w-[26rem] text-left text-base/6 text-white">
              Collaborate with us to find the best strategy and incentives to
              fit the needs of your market.
            </p>
          </WobbleCard>
          <WobbleCard
            containerClassName="col-span-1 lg:col-span-2 h-full bg-primary min-h-[500px] lg:min-h-[300px]"
            className=""
          >
            <div className="max-w-xs">
              <h2 className="text-3xl font-inter font-bold text-white">
                UI libraries
              </h2>
              <p className="mt-4 text-left  text-base/6 text-white">
                From pre-made blocks to fully-customizable elements that you own
                from the start. No mandatory Reftree branding.
              </p>
            </div>
            <img
              src="/illustrations/undraw_building_blocks_re_5ahy.svg"
              width={300}
              height={300}
              alt="linear demo image"
              className="absolute right-10 lg:-right-5 xl:right-20 -bottom-2 lg:-bottom-10 object-contain rounded-2xl"
            />
          </WobbleCard>
        </div>
      </div>
    </section>
  );
}
